import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Breadcrumbs,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import FileUpload from "react-material-file-upload";
import axios from "axios";
import Header from "../../Header";
import Toast from "../../global/Toast";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const initialValues = {
  photos_type: "",
};

const ImportBeneficiariesPhotos = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [uploadResults, setUploadResults] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const validationSchema = Yup.object({
    photos_type: Yup.string().required(
      t("beneficiaries.import.required_photo_type")
    ),
  });

  // Component to display the results in a modal
  const UploadResultsModal = ({ open, onClose, uploadResults }) => (
    <Modal open={open} onClose={onClose} style={{ overflow: "auto" }}>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backdropFilter: "blur(3px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{ maxWidth: "80%", maxHeight: "80%", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {t("beneficiaries.import.photo")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {t("beneficiaries.import.beneficiary_name")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="right">
                  {t("beneficiaries.import.status")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="right">
                  {t("beneficiaries.import.message")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadResults.map((result, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{ color: result.status ? "green" : "red" }}
                    component="th"
                    scope="row"
                  >
                    {result.image}
                  </TableCell>
                  <TableCell sx={{ color: result.status ? "green" : "red" }}>
                    {result.beneficiary_name || "N/A"}
                  </TableCell>
                  <TableCell
                    sx={{ color: result.status ? "green" : "red" }}
                    align="right"
                  >
                    {result.status ? "Success" : "Failed"}
                  </TableCell>
                  <TableCell
                    sx={{ color: result.status ? "green" : "red" }}
                    align="right"
                  >
                    {result.message}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
            <button className="btn btn-primary" onClick={onClose}>
              Close
            </button>
          </Box>
        </TableContainer>
      </Box>
    </Modal>
  );

  // Close modal and reset states
  const handleModalClose = () => {
    setIsModalOpen(false);
    setFiles([]); // Reset the files state
    setUploadResults([]); // Optionally, clear the upload results as well
    setUploadError(null); // Optionally, clear any upload errors
  };

  // Redux state for auth token
  const token = useSelector(selectCurrentToken);

  // Handle file upload form submission
  const handleFormSubmit = async (values, { setSubmitting }) => {
    if (files.length === 0) {
      setUploadError(t("beneficiaries.import.no_files_selected"));
      setSubmitting(false);
      return;
    }

    // Prepare data for POST request
    const formData = new FormData();
    formData.append("photos_type", values.photos_type);

    files.forEach((file) => {
      formData.append("photos[]", file);
    });

    const uploadUrl = `${process.env.REACT_APP_BASE_URL}/beneficiaries/import/photos`;

    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.data?.status === 200) {
        setUploadResults(response.data.data);
        setIsModalOpen(true);
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header
        title={t("beneficiaries.import.beneficiaries")}
        subtitle={t("beneficiaries.import.beneficiaries_photos")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/beneficiaries">
          <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Import Beneficiaries")}
        </Typography>
      </Breadcrumbs>

      <ToastContainer />

      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{ border: "1px solid #fff", borderRadius: "5px" }}
              display="grid"
              gap="20px"
            >
              <FormControl
                fullWidth
                error={touched.photos_type && Boolean(errors.photos_type)}
              >
                <InputLabel id="photo-type-label">
                  {t("beneficiaries.import.photo_type")}
                </InputLabel>
                <Select
                  labelId="photo-type-label"
                  id="photo-type-select"
                  value={values.photos_type}
                  label={t("beneficiaries.import.photo_type")}
                  onChange={handleChange}
                  name="photos_type"
                >
                  <MenuItem value="KOBO">
                    {t("beneficiaries.import.kobo_photos")}
                  </MenuItem>
                  <MenuItem value="REGISTRATION">
                    {t("beneficiaries.import.registration_from_photos")}
                  </MenuItem>
                  {/* Add other photo types as needed */}
                </Select>
                {touched.photos_type && errors.photos_type && (
                  <Typography color="error" variant="body2">
                    {errors.photos_type}
                  </Typography>
                )}
              </FormControl>

              <Typography variant="body1">
                {t("beneficiaries.import.upload_photos")}:{" "}
                {t("beneficiaries.import.instructions")}
              </Typography>

              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h7" component="div">
                  <ul>
                    <li>{t("beneficiaries.import.select_multiple photos")}</li>
                  </ul>
                </Typography>
              </Box>

              <FileUpload
                value={files}
                onChange={setFiles}
                title={t("beneficiaries.import.title_text")}
                buttonText={t("beneficiaries.import.select_photos")}
                accept=".jpg, .jpeg, .png"
              />
            </Box>

            {uploadError && (
              <Typography color="error">{uploadError}</Typography>
            )}

            <Box display="flex" justifyContent="end" mt="20px">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? "loading.."
                  : t("beneficiaries.import.upload_photos")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
      <UploadResultsModal
        open={isModalOpen}
        onClose={handleModalClose}
        uploadResults={uploadResults}
      />
    </Box>
  );
};

export default ImportBeneficiariesPhotos;
