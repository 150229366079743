import { useTranslation } from "react-i18next";
import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
} from "@mui/material";
import { ToastContainer } from "react-toastify";

import Toast from "../../global/Toast";
import React from "react";

const ConfirmChangeStatus = ({
  onClose,
  action,
  child,
  parent,
  open,
  handleChangeStatus, childLabel, parentLabel
}) => {
  const { t } = useTranslation();

  const childTitle = child?.title ? child?.title : child?.full_name;
  const parentTitle = parent?.title ? parent?.title : parent?.full_name;

  const handleClose = async (value) => {
    if (value == "YES") {
      await handleChangeStatus(child, action);
    }

    onClose(child);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <ToastContainer />
      <DialogTitle>
        {action}{" "}
        <b>{childTitle}</b>{" "}{childLabel}{" "}
        {t("packages.add_entitlements.in")}{" "}
        <b>{parentTitle}</b>{" "}
        {parentLabel}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">
          {t("global.dialog.confirm_dialog.content", {
            action,
            childTitle,
            parentTitle,
          })}
        </Typography>

        <Box display="flex" gap="10px" justifyContent="end" mt="20px">
          <button onClick={() => handleClose("YES")} className="btn btn-primary">
            {t("global.dialog.confirm_dialog.buttons.yes", { action })}
          </button>
          <button onClick={() => handleClose("NO")} autoFocus className="btn btn-danger">
            {t("global.dialog.confirm_dialog.buttons.no", { action })}
          </button>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default ConfirmChangeStatus;
