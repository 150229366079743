import React, { useState, useEffect } from "react";
import {
  Box,
  Alert,
  AlertTitle,
  Button,
  Popover,
  TextField,
  Typography,
  Breadcrumbs,
  Card,
} from "@mui/material";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import {
  useGetEntitlementsQuery,
  useGetUserEntitlementsQuery,
  useAddUserEntitlementMutation,
} from "../../../features/entitlements/entitlementsApiSlice";

import { useGetUserQuery } from "../../../features/users/usersApiSlice";
import { useParams, Link } from "react-router-dom";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import * as yup from "yup";
import { Formik } from "formik";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

import ConfirmChangeStatus from "../shared/ConfirmChangeStatus";
import {
  useUpdateServiceProviderEntitlementMutation,
  useUpdateServiceProviderEntitlementStatusMutation,
} from "../../../features/users/serviceProvidersApiSlice";

const ServiceProviderAddEntitlements = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const [entitlements, setEntitlements] = useState([]);
  const [presentEntitlements, setPresentEntitlements] = useState([]);
  const [activeEntitlements, setActiveEntitlements] = useState([]);
  const { data, isLoading, isSuccess, isFetching } = useGetEntitlementsQuery({
    page,
    pageSize,
  });
  const [action, setAction] = useState(null);
  const [openModalDialog, setOpenModalDialog] = useState(false);

  const handleCloseModalDialog = () => {
    setOpenModalDialog(false);
  };
  const [updateEntitlementStatus, { isLoading: isUpdateStatusLoading }] =
    useUpdateServiceProviderEntitlementStatusMutation();

  // Get user current entitlements
  const {
    data: entitlementsData,
    isLoading: fetchingEntitlements,
    isSuccess: entitlementsSuccess,
    refetch: refetchEntitlements,
  } = useGetUserEntitlementsQuery(id);

  useEffect(() => {
    if (entitlementsData?.entitlements) {
      const activeEntitlements = entitlementsData.entitlements.filter(
        (entitlement) => entitlement.status === "ACTIVE"
      );
      setPresentEntitlements(entitlementsData.entitlements);
      setActiveEntitlements(activeEntitlements);
    }
  }, [entitlementsData, fetchingEntitlements, entitlementsSuccess]);

  useEffect(() => {
    if (data?.data) setEntitlements(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // check if the title value of tale matches present entiltment title value
  const checkNameMatch = (name) => {
    return presentEntitlements.some(
      (entitlement) => entitlement.title === name
    );
  };

  // Separate matching and non-matching entitlements
  const matchingEntitlements = [];
  const nonMatchingEntitlements = [];
  entitlements.forEach((entitlement) => {
    if (
      presentEntitlements.some((present) => present.title === entitlement.title)
    ) {
      matchingEntitlements.push(entitlement);
    } else {
      nonMatchingEntitlements.push(entitlement);
    }
  });

  // Concatenate arrays to place matching entitlements at the bottom
  const sortedEntitlements = [
    ...nonMatchingEntitlements,
    ...matchingEntitlements,
  ];

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const [serviceProvider, setServiceProvider] = useState([]);
  const { data: user, isSuccess: userSuccess } = useGetUserQuery(id);

  useEffect(() => {
    if (user?.data) setServiceProvider(user?.data);
  }, [user, userSuccess]);

  const [selectedEntitlementName, setSelectedEntitlementName] = useState("");
  const [selectedEntitlement, setSelectedEntitlement] = useState("");
  const [addUserEntitlement] = useAddUserEntitlementMutation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const handleClick = (event, entitlement) => {
    setAnchorEl(event.currentTarget);
    setSelectedEntitlement(entitlement);
    setSelectedEntitlementName(JSON.parse(entitlement).title);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      user_id: serviceProvider.id,
      entitlement_id: JSON.parse(selectedEntitlement).id,
    };
    try {
      const response = await addUserEntitlement(payload).unwrap();

      if (response.status === 200) {
        handleClose();
      } else {
        Toast(response?.message, "error");
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const initialValues = {
    price: "",
    subsidy: "",
    copay: "",
  };

  const entitlementsSchema = yup.object().shape({
    price: yup.string().required(t("packages.form.helpertext.price")),
    subsidy: yup
      .string()
      .required(t("packages.form.helpertext.subsidy_amount")),
    copay: yup.string().required(t("packages.form.helpertext.copay_amount")),
  });

  // delete function
  const handleModalOpen = (entitlement, action) => {
    setSelectedEntitlement(entitlement);
    setAction(action);
    setOpenModalDialog(true);
  };

  const handleStatusChange = async (entitlement, action) => {
    const userId = serviceProvider?.id;
    const entitlementId =
      typeof selectedEntitlement === "string"
        ? JSON.parse(selectedEntitlement)?.id
        : selectedEntitlement?.id;

    const actionStatusMap = {
      ACTIVATE: "ACTIVE",
      DEACTIVATE: "INACTIVE",
      ARCHIVE: "ARCHIVED",
    };

    const status = actionStatusMap[action] || "";

    // Ensure you have non-null values before proceeding
    if (!userId || !entitlementId) {
      Toast(t("service_providers.edit_entitlements.missing_request_fields"));
      return;
    }

    try {
      const response = await updateEntitlementStatus({
        entitlement_id: entitlementId,
        user_id: userId,
        new_status: status,
      }).unwrap();

      if (response.status === 200) {
        Toast(
          t("service_providers.edit_entitlements.update_success"),
          "success"
        );
        refetchEntitlements();
      } else {
        Toast(response?.message, "error");
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      renderCell: (params) => {
        const getEntitlement = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        const isMatching = activeEntitlements.some(
          (present) => present.title === params.row.title
        );
        const isInactive = presentEntitlements.some(
          (entitlement) =>
            entitlement.title === params.row.title &&
            entitlement.status === "INACTIVE"
        );

        if (isMatching) {
          // Render nothing for matching entitlements
          return (
            <Button
              color="error"
              variant="outlined"
              onClick={() => handleModalOpen(getEntitlement(), "DEACTIVATE")}
            >
              {t("global.table.delete_button")}
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </Button>
          );
        } else if (isInactive) {
          return (
            <Button
              color="warning"
              variant="outlined"
              onClick={() => handleModalOpen(getEntitlement(), "ACTIVATE")}
            >
              {t("global.table.activate_button")}
              <CheckCircleOutlinedIcon fontSize="small" />
            </Button>
          );
        } else {
          return (
            <>
              <Box display="flex" flexDirection="column">
                <Button
                  color="secondary"
                  aria-describedby={popoverId}
                  variant="outlined"
                  onClick={(e) => handleClick(e, getEntitlement())}
                  size="small"
                >
                  {t("global.buttons.add")} <AddCardOutlinedIcon />
                </Button>
                <Popover
                  id={popoverId}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography variant="h6" ml="10px">
                    {t("service_providers.add_entitlements.add")}{" "}
                    <b>{selectedEntitlementName}</b>{" "}
                    {t("entitlements.form.label.entitlement")}{" "}
                    {t("service_providers.add_entitlements.to")}{" "}
                    <b>{serviceProvider.user_name}</b>{" "}
                    {t("service_providers.add_entitlements.service_provider")}
                  </Typography>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={entitlementsSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="number"
                          label={t("entitlements.form.label.price")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.price}
                          name="price"
                          error={!!touched.price && !!errors.price}
                          helperText={touched.price && errors.price}
                        />
                        <TextField
                          fullWidth
                          variant="filled"
                          type="number"
                          label={t("packages.form.label.copay_amount")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.copay}
                          name="copay"
                          error={!!touched.copay && !!errors.copay}
                          helperText={touched.copay && errors.copay}
                        />
                        <TextField
                          fullWidth
                          variant="filled"
                          type="number"
                          label={t("packages.form.label.subsidy_amount")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.subsidy}
                          name="subsidy"
                          error={!!touched.subsidy && !!errors.subsidy}
                          helperText={touched.subsidy && errors.subsidy}
                        />
                        <Box
                          display="flex"
                          gap="10px"
                          justifyContent="end"
                          my="20px"
                          mr="2px"
                        >
                          <button type="submit" className="btn btn-primary">
                            {t("global.buttons.add_entitlements_tosp")}
                          </button>
                          <button
                            autoFocus
                            className="btn btn-danger"
                            onClick={handleClose}
                          >
                            {t("global.page.cancel_button")}
                          </button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Popover>
              </Box>
            </>
          );
        }
      },
    },
    {
      field: "title",
      headerName: t("global.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.form.label.description"),
      flex: 1,
    },

    { field: "unit", headerName: t("entitlements.form.label.unit"), flex: 1 },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("service_providers.add_entitlements.title")}
        subtitle={t("service_providers.add_entitlements.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/service-providers">
          <Typography variant="body2">
            {t("sidebar.Service Providers")}
          </Typography>
        </Link>
        <Link
          style={LinkColor()}
          color="inherit"
          to={`/service-providers/view/${id}`}
        >
          <Typography variant="body2">
            {t("service_providers.view.view")}
          </Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("global.buttons.add_entitlements")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>
          {t("service_providers.add_entitlements.alert_head")}
        </AlertTitle>
        {t("service_providers.add_entitlements.alert_message")}{" "}
        <strong>{serviceProvider.user_name}</strong>
      </Alert>

      {/* Entitlements List */}
      <Box className="data_grid_box_container" sx={{ mt: "5rem" }}>
        <Typography
          sx={{ fontSize: "16px", fontStyle: "bold", marginBottom: "10px" }}
        >
          Select Entitlement To Add
        </Typography>
        <StyledDataGrid
          rows={sortedEntitlements}
          columns={columns}
          pagination
          pageSize={pagination.perPage}
          rowCount={pagination.total}
          onPageChange={handlePageChange}
          loading={isFetching}
          paginationMode="server"
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={default_rows_per_page_options}
        />
      </Box>
      <Box>
        <ConfirmChangeStatus
          open={openModalDialog}
          onClose={handleCloseModalDialog}
          child={selectedEntitlement}
          parent={{
            title: `${serviceProvider?.first_name} ${serviceProvider?.last_name}`,
          }}
          action={action}
          handleChangeStatus={handleStatusChange}
          childLabel={t('entitlements.form.label.entitlement')}
          parentLabel={t('service_providers.add_entitlements.service_provider')}
        />
      </Box>
    </Box>
  );
  return content;
};

export default ServiceProviderAddEntitlements;
